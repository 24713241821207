<template lang="pug">
v-select.dataInput(ref='data', v-model='data', :items='picker')
	template(v-slot:selection='{ item, index }')
		span {{ item }}
</template>
<script>
export default {
	props: ['dpstStatus'],
	data() {
		return {
			modal: false,
			label: this.$t('common.column'),
			data: this.$t('charts.revenue'),
			language: 'en',
			quickPick: '1',
			quickItems: [
				{
					label: this.$t('charts.revenue'),
					value: '1',
					disable: false,
				},
				{
					label: this.$t('charts.wdrl'),
					value: '2',
					disable: false,
				},
			],
			picker: [this.$t('charts.revenue'), this.$t('charts.wdrl')],
		};
	},
	mounted() {
		this.language = localStorage.getItem('language');
	},
	computed: {},
	watch: {
		data: function (val) {
			this.$emit('emitUpdateDpstData', val);
		},
		dpstStatus: function (val) {
			if (val === this.$t('charts.revenue')) {
				this.data = this.$t('charts.revenue');
				this.saveData(this.data);
			}
			if (val === this.$t('charts.wdrl')) {
				this.data = this.$t('charts.wdrl');
				this.saveData(this.data);
			}
		},
	},
	methods: {
		cancelData() {
			this.modal = false;
		},
		saveData(data) {
			this.data = data;
			this.$emit('emitUpdateDpstData', data);
		},
	},
};
</script>