<template lang="pug">
v-select.dataInput(ref='data', v-model='data', :items='picker')
	template(v-slot:selection='{ item, index }')
		span {{ item }}
</template>
<script>
export default {
	props: ['turnDataStatus'],
	data() {
		return {
			modal: false,
			label: this.$t('common.column'),
			data: this.$t('charts.inPromoTurnover'),
			language: 'en',
			picker: [this.$t('charts.inPromoTurnover')],
		};
	},
	mounted() {
		this.language = localStorage.getItem('language');
	},
	computed: {},
	watch: {
		data: function (val) {
			this.$emit('emitupdateTurnoverData', val);
		},
		turnDataStatus: function (val) {
			if (val === this.$t('charts.inPromoTurnover')) {
				this.saveData(this.data);
			}
		},
	},
	methods: {
		cancelData() {
			this.modal = false;
		},
		saveData(data) {
			this.data = data;
			this.$emit('emitupdateTurnoverData', data);
		},
	},
};
</script>