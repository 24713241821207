<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgGmApvBetAmt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgNetWin')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDAU')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.gmApvBetAmtPu')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgDpstAmt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgWdrlAmt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.dpstUsers')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.dpstAmtPu')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.totalHouseEdge')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgPromoCredit')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgProfit')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avgRevenueAmt')
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption4).length !== 0')
						v-chart(:option='pieOption4', ref='pieOption4', autoresize)
					v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(barOption1).length !== 0')
						//- v-col#netWinSelectBox.dataSelectBox
						//- 	TurnoverPicker(:turnDataStatus='turnOverData', @emitupdateTurnoverData='updateTurnoverData')
						v-chart(:option='barOption1', ref='barOption1', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption2).length !== 0')
						v-chart(:option='barOption2', ref='barOption2', autoresize)
					v-col.chartWrap.mixChart(cols='12', lg='8', v-if='Object.keys(barOption3).length !== 0')
						v-col#netWinSelectBox.dataSelectBox
							NetWinPicker(:netWinDataStatus='netWinData', @emitupdateNetWinData='updateNetWinData')
						v-chart.chartMoveUp_more(:option='barOption3', ref='barOption3', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption4).length !== 0')
						v-chart(:option='barOption4', ref='barOption4', autoresize)
					v-col.chartWrap.mixChart(cols='12', lg='8', v-if='Object.keys(barOption5).length !== 0')
						v-col#netWinSelectBox.dataSelectBox
							RevenuePicker(:dpstStatus='dpstData', @emitUpdateDpstData='updateDpstData')
						v-chart.chartMoveUp_more(:option='barOption5', ref='barOption5', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption1).length !== 0')
						v-chart(:option='pieOption1', ref='pieOption1', autoresize)
					v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(lineOption2).length !== 0')
						v-chart(:option='lineOption2', ref='lineOption2', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(lineOption1).length !== 0')
						v-chart(:option='lineOption1', ref='lineOption1', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(lineOption5).length !== 0')
						v-chart(:option='lineOption5', ref='lineOption5', autoresize)
				span(v-if='Object.keys(funnelOption1).length !== 0')
					v-col.chartWrapNoMargin
						b {{ $t('charts.newUsersDailyConversion') }}
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(funnelOption1).length !== 0')
						v-chart.chartMoveDown(:option='funnelOption1', ref='funnelOption1', autoresize)
					ChartsSwitch(:firstOption='lineOption3' :secondOption='stackBarOption1')
				span(v-if='Object.keys(funnelOption2).length !== 0')
					v-col.chartWrapNoMargin
						b {{ $t('charts.newUsersDailyRetention') }}
				v-row
					v-col.chartWrap(cols='12', lg='4', xs='12', v-if='Object.keys(funnelOption2).length !== 0')
						v-chart.chartMoveDown(:option='funnelOption2', ref='funnelOption2', autoresize)
					ChartsSwitch(:firstOption='lineOption4' :secondOption='stackBarOption2')
				//- v-row
				//- 	v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption2).length !== 0')
				//- 		v-chart(:option='pieOption2', ref='pieOption2', autoresize)
				//- 	ChartsSwitch(:firstOption='lineOption5' :secondOption='stackBarOption3')
				v-row
					v-col.dataSelectBox
						SwitchData(:dataStatus='switchValue', @emitupdateData='updateData', v-if='dataSwitch')
					v-btn.date-btn.mr-3(style= 'z-index: 3;' color="blue darken-2" @click='resetGamingCharts' large) {{$t('common.reset')}}
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption6).length !== 0')
						v-chart(:option='barOption6', @click='getGameKind' ref='barOption6', autoresize)
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption7).length !== 0')
						v-chart(:option='barOption7', @click='getBrand' ref='barOption7', autoresize)
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption8).length !== 0')
						v-chart(:option='barOption8', ref='barOption8', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption3).length !== 0')
						v-chart(:option='pieOption3', ref='pieOption3', autoresize)
					v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(heatMapOption1).length !== 0')
						v-chart(:option='heatMapOption1', ref='heatMapOption1', autoresize)
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import { errorLogger } from '@/util/logger';
import { bar } from '@/assets/echarts/basicCommon/dataMointor';
import { summaryBar } from '@/assets/echarts/ocmsCommon/customer/entire';
import { mixBar_Line } from '@/assets/echarts/ocmsCommon/mixBar_Line';
import { basicLineChart } from '@/assets/echarts/ocmsCommon/basicLineChart';
// import { barPercent } from '@/assets/echarts/ocmsCommon/barPercent';
import { bar as norBar, barPercent, line , pie} from '@/assets/echarts/ocmsCommon/house/reg';
import { userFunnelChart } from '@/assets/echarts/ocmsCommon/funnel';
import { revenueChart } from '@/assets/echarts/ocmsCommon/revenue';
import { heatMap, pie as norPie } from '@/assets/echarts/ocmsCommon/customer/single';
import { getSingleSite } from '@/api/Customer';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	toThousandslsFilter,
	rateOfExchange,
	roundFilter,
	roundOff,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	getNaN,
} from '@/util/format';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import NetWinPicker from '@/components/DataPicker/NetWinPicker';
import RevenuePicker from '@/components/DataPicker/RevenuePicker';
import TurnoverPicker from '@/components/DataPicker/TurnoverPicker';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import Kanban from '@/components/kanbans/Kanban';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import SwitchData from '@/components/DataPicker/SwitchData';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import timeIntervalType from '@/assets/constant/timeIntervalType';
import statusMsg from '@/util/http';
import { exportXlsx } from '@/util/xlsx';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		DaliyPicker,
		NetWinPicker,
		TurnoverPicker,
		ExportBtn,
		Kanban,
		RevenuePicker,
		SingleSelectCurrency,
		SwitchData,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			dates: [],
			dateType: dateType.BRIEF_DATE,
			searchDates: [],
			percentSwitchShow: false,
			percentSwitch: false,
			percentSwitchTitle: this.$t('charts.showPercent'),
			netWinData: this.$t('charts.promoCredit'),
			dpstData: this.$t('charts.revenue'),
			turnOverData: this.$t('charts.inPromoTurnover'),
			groupNameAfwd: timeIntervalType['afwd'],
			groupKeyAfwd: Object.keys(timeIntervalType['afwd']),
			groupValueAfwd: Object.values(timeIntervalType['afwd']),
			groupNameBkwd: timeIntervalType['bkwd'],
			groupKeyBkwd: Object.keys(timeIntervalType['bkwd']),
			groupValueBkwd: Object.values(timeIntervalType['bkwd']),
			allGroup: ['1', '3', '7', '15', '30', '90', '360', '-1'],
			newGroup: [ '30', '15', '7', '3', '1' ],
			oldGroup: [ '-1', '360', '90' ],
			groupColor: [
				COLORS.carrot[2],
				COLORS.carrot[3],
				COLORS.carrot[4],
				COLORS.carrot[5],
				COLORS.carrot[6],
				COLORS.belize_hole[3],
				COLORS.belize_hole[4],
				COLORS.belize_hole[5],
				COLORS.carrot[7],
				COLORS.belize_hole[7],
			],
			switchValue: this.$t('charts.turnoverDesc'),
			dataSwitch: false,
			bdName: new Map(),
			bdValue: new Map(),
			gkName: new Map(),
			gkValue: new Map(),
			gtName: new Map(),
			gtValue: new Map(),
			cardData: {},
			barOption1: {},
			barOption2: {},
			barOption3: {},
			barOption4: {},
			barOption5: {},
			stackBarOption1: {},
			stackBarOption2: {},
			stackBarOption3: {},
			lineOption1: {},
			lineOption2: {},
			lineOption3: {},
			lineOption4: {},
			lineOption5: {},
			pieOption1: {},
			pieOption2: {},
			pieOption3: {},
			pieOption4: {},
			funnelOption1: {},
			funnelOption2: {},
			barOption6: {},
			barOption7: {},
			barOption8: {},
			heatMapOption1: {},
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.percentSwitchShow = true;
			this.percentSwitch = false;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					domain: [this.value],
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getSingleSite(reqData);
					if (res.status === statusCode.STATUS_OK) {
						localStorage.setItem('currency', this.currency);
						const result = res.result.res;
						// console.log('result', result);
						this.getExportResult = JSON.parse(JSON.stringify(result));
						this.recapEarly = result.ctx_recap_early[0];
						this.recap = result.ctx_recap[0];
						this.termly = result.ctx_termly;
						this.actSum = result.ctx_act_sum;
						this.actOrg = result.ctx_act_org;
						this.cvtAllEarly = result.ctx_cvt_all_early[0];
						this.cvtAll = result.ctx_cvt_all[0];
						this.cvtOrg = result.ctx_cvt_org;
						this.kpiOrg = result.ctx_kpi_org;
						// this.regSum = result.ctx_reg_sum;
						// this.regOrg = result.ctx_reg_org;
						this.gaming = JSON.parse(JSON.stringify(result.ctx_gaming));
						if (Object.is(this.gaming.length, 0)) {
							this.dataSwitch = false;
						} else {
							this.dataSwitch = true;
						}
						this.transOv = result.ctx_trans;
						this.periodOv = result.ctx_period_ov;

						this.actOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.cvtOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						// this.regOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.termly.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						this.kpiOrg.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
						// this.dailyGamingUsers = trans(this.regOrg, 'dim_ptt', 'gm_users');

						this.acuChart(this.termly);
						this.turnOverPieChart(this.recap);
						this.turnOverChart(this.termly);
						this.netWinBarChart(this.recap);
						this.netWinChart(this.termly);
						this.dpstBarChart(this.recap);
						this.dpstChart(this.termly);
						this.convertFunnelChart(this.cvtAll);
						this.convertLineChart(this.cvtOrg);
						this.convertBarChart(this.cvtOrg);
						this.retentionFunnelChart(this.actSum);
						this.retentionBarChart(this.actOrg);
						this.retentionLineChart(this.actOrg);
						this.dailyStickinessLineChart(this.kpiOrg);
						// this.regPieChart(this.regSum);
						// await this.regLineChart(this.regOrg);
						// this.regBarChart(this.regOrg);
						this.gameKindChart(this.gaming);
						this.brandChart(this.gaming);
						this.gameThemeChart(this.gaming);
						this.dpstWayChart(this.transOv);
						this.popularCharts(this.periodOv);
						this.cardDisplay(this.recap, this.recapEarly);
					}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async cardDisplay(res, earlyRes) {
			this.cardData = {
				avgGmApvBetAmt: {
					mainTitle: this.$t('charts.avgGmApvBetAmt'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_apv_bet || 0,
					subData: earlyRes.avg_apv_bet || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalBet'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_apv_bet || 0,
					toolSubData: earlyRes.ttl_apv_bet || 0,
					note: 'value'
				},
				avgNetWin: {
					mainTitle: this.$t('charts.avgHouseNetWin'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_house_net_win || 0,
					subData: earlyRes.avg_house_net_win || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalNetWin'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_house_net_win || 0,
					toolSubData: earlyRes.ttl_house_net_win || 0,
					note: 'value'
				},
				avgDAU: {
					mainTitle: this.$t('charts.avgDAU'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_gm_users || 0,
					subData: earlyRes.avg_gm_users || 0,
					note: 'people'
				},
				gmApvBetAmtPu: {
					mainTitle: this.$t('charts.gmApvBetAmtPu'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.pupd_apv_bet || 0,
					subData: earlyRes.pupd_apv_bet || 0,
					note: 'people'
				},
				avgDpstAmt: {
					mainTitle: this.$t('charts.avgDeposit'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_dpst_amt || 0,
					subData: earlyRes.avg_dpst_amt || 0,
					tooltip: true,
					toolMainTitle: this.$t('member.totalDpst'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_dpst_amt || 0,
					toolSubData: earlyRes.ttl_dpst_amt || 0,
					note: 'value'
				},
				avgWdrlAmt: {
					mainTitle: this.$t('charts.avgWithdraw'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_wdrl_amt || 0,
					subData: earlyRes.avg_wdrl_amt || 0,
					tooltip: true,
					toolMainTitle: this.$t('customer.ttlWdrlAmt'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_wdrl_amt || 0,
					toolSubData: earlyRes.ttl_wdrl_amt || 0,
					is_negative_growth: true,
					note: 'value'
				},
				dpstUsers: {
					mainTitle: this.$t('charts.dpstUsersK'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_dpst_users || 0,
					subData: earlyRes.avg_dpst_users || 0,
					note: 'people'
				},
				dpstAmtPu: {
					mainTitle: this.$t('customer.dpstAmtPu'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.pupd_dpst_amt || 0,
					subData: earlyRes.pupd_dpst_amt || 0,
					note: 'people'
				},
				totalHouseEdge: {
					mainTitle: this.$t('charts.totalHouseEdge'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.house_edge || 0,
					subData: earlyRes.house_edge || 0,
					note: 'percent'
				},
				avgPromoCredit: {
					mainTitle: this.$t('charts.avgPromoCredit'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_bonus_amt || 0,
					subData: earlyRes.avg_bonus_amt || 0,
					tooltip: true,
					toolMainTitle: this.$t('member.totalPromoCredit'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_bonus_amt || 0,
					toolSubData: earlyRes.ttl_bonus_amt || 0,
					is_negative_growth: true,
					note: 'value'
				},
				avgProfit: {
					mainTitle: this.$t('charts.avgProfit'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_profit_amt || 0,
					subData: earlyRes.avg_profit_amt || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalProfit'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_profit_amt || 0,
					toolSubData: earlyRes.ttl_profit_amt || 0,
					note: 'value'
				},
				avgRevenueAmt: {
					mainTitle: this.$t('charts.avgRevenue'),
					subTitle: this.$t('charts.earlyNumber'),
					mainData: res.avg_revenue_amt || 0,
					subData: earlyRes.avg_revenue_amt || 0,
					tooltip: true,
					toolMainTitle: this.$t('charts.totalRevenue'),
					toolSubTitle: this.$t('charts.thisTerm'),
					toolMainData: res.ttl_revenue_amt || 0,
					toolSubData: earlyRes.ttl_revenue_amt || 0,
					note: 'value'
				},
			};
		},
		async acuChart(res) {
			const gmUsersData = [],
				dpstData = [],
				promoData = [],
				loginData = [],
				regData = [],
				xAxisData = [];
			const legendData = [
				this.$t('charts.regUsersPerDay'),
				this.$t('charts.loginUsersPerDay'),
				this.$t('charts.gamingUsersPerDay'),
				this.$t('charts.dpstUsersPerDay'),
				this.$t('charts.promoUsersPerDay'),
			];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					gmUsersData.push(res[i].gm_users);
					dpstData.push(res[i].dpst_users);
					promoData.push(res[i].promo_join_users);
					loginData.push(res[i].login_users);
					regData.push(res[i].reg_users);
				}
			}
			this.lineOption1 = await basicLineChart();
			this.lineOption1.title.text = this.$t('charts.overViewDAU');
			this.lineOption1.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(dateType.BRIEF_DATE);
			};
			this.lineOption1.xAxis.data = xAxisData;
			this.lineOption1.grid = { bottom: '24%', left: '13%' };
			this.lineOption1.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				icon: 'circle',
				data: [
					{ name: legendData[0] },
					{ name: legendData[1] },
					{ name: legendData[2] },
					{ name: legendData[3] },
					{ name: legendData[4] },
				],
			};
			// this.lineOption1.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
			// this.lineOption1.tooltip.textStyle.color = 'white';
			this.lineOption1.tooltip.formatter = (params) => {
				let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
				}
				return str;
			};
			this.lineOption1.series = [
				{
					type: 'line',
					name: this.$t('charts.regUsersPerDay'),
					data: regData,
					color: COLORS.light_purple,
				},
				{
					type: 'line',
					name: this.$t('charts.loginUsersPerDay'),
					data: loginData,
					color: COLORS.water_blue_green,
				},
				{
					type: 'line',
					name: this.$t('charts.gamingUsersPerDay'),
					data: gmUsersData,
					color: COLORS.light_green,
				},
				{
					type: 'line',
					name: this.$t('charts.dpstUsersPerDay'),
					data: dpstData,
					color: COLORS.mustard,
				},
				{
					type: 'line',
					name: this.$t('charts.promoUsersPerDay'),
					data: promoData,
					color: COLORS.light_pink,
				},
			];
		},
		async turnOverPieChart(res) {
			const legendData = [this.$t('charts.inPromoTurnover'), this.$t('charts.noPromoTurnover')];
			if (res) {
				this.pieOption4 = norPie();
				this.pieOption4.color = [COLORS.wisteria[5], COLORS.wisteria[3]];
				this.pieOption4.title.text = this.$t('charts.promoTurnOver');
				this.pieOption4.title.textStyle.fontSize = 20;
				this.pieOption4.legend.data = legendData;
				this.pieOption4.graphic.style.text = this.$t('charts.avgDailyBet') + '\n' + roundOffFilter(res.avg_apv_bet);
				this.pieOption4.tooltip.formatter = (params) => `
					${params.marker}${params.name}: ${roundOffFilter(params.value)} (${rdPercent(params.value / res.avg_apv_bet)})`;
				this.pieOption4.series[0].data = [
					{
						value: res.avg_in_promo_bet,
						name:  this.$t('charts.inPromoTurnover'),
					},
					{
						value: res.avg_no_promo_bet,
						name: this.$t('charts.noPromoTurnover'),
					}
				];
			}
		},
		async turnOverChart(res) {
			const data = [], proData = [], xAxisData = [];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					data.push(res[i].apv_bet);
					proData.push(res[i].in_promo_bet);
				}
			}
			this.barOption1 = await mixBar_Line();
			this.barOption1.color = COLORS.blue;
			// this.barOption1.title.text = this.$t('charts.overViewTurnOverD');
			this.barOption1.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [
					{ name: this.$t('charts.turnover') },
					{ name: this.$t('charts.inPromoTurnover'), icon: 'circle' },
				],
			};
			this.barOption1.xAxis.data = xAxisData;
			this.barOption1.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(dateType.BRIEF_DATE);
			};
			this.barOption1.yAxis[0].axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.barOption1.yAxis[1].axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.barOption1.grid = { top: '23%', left: '16%' };
			this.barOption1.tooltip.formatter = (params) => {
				let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
				}
				return str;
			};
			this.barOption1.series.name = this.$t('charts.turnover');
			this.barOption1.series.markPoint = {
				label: {
					formatter: (parameter) => nFormatter(parameter.value, 1),
				},
				symbolSize: [1, 1],
				data: [
					{ type: 'max', name: '最大值', label: { position: 'top', color: '#FFF' } },
					{ type: '', name: '最小值', label: { position: 'top', color: '#FFF' } },
				],
			};
			this.barOption1.series = [
				{
					name: this.$t('charts.turnover'),
					type: 'bar',
					data: data,
					color: COLORS.light_blue,
				},
				{
					name: this.$t('charts.inPromoTurnover'),
					type: 'line',
					data: proData,
					// yAxisIndex: 1,
					color: COLORS.wisteria[5],
				},
			];
		},
		async netWinBarChart(res) {
			const xAxisData = [
				this.$t('charts.avgNetWin'),
				this.$t('charts.avgPromoCredit'),
				this.$t('charts.avgProfit'),
			];
			if (res) {
				const data = [res.avg_house_net_win, res.avg_bonus_amt, res.avg_profit_amt];
				this.barOption2 = norBar({
					color: [COLORS.dark_green, '#ffcdd2', '#E1E100'],
					title: this.$t('charts.netWinSummary'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption2.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'right',
							color: '#FFF',
							formatter: (params) => {
								return `${xAxisData[params.componentIndex]}: ${roundOffFilter(data[params.componentIndex])}`;
							}
						},
						data: [data[i]]
					});
				}
				this.barOption2.series[0].label.position = 'inside';
				this.barOption2.grid.right = '15%';
				this.barOption2.xAxis.show = false;
				this.barOption2.yAxis[0].position = 'right';
				this.barOption2.tooltip.show = false;
				// this.barOption2.tooltip.formatter = (params) => {
				// 	let str = ``;
				// 	for (let i of params) {
				// 		str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
				// 	}
				// 	return str;
				// };
			}
		},
		async netWinChart(res) {
			const xAxisData = [];
			const netWinData = [];
			const promoCreditData = [];
			const profitData = [], heData = [];
			const HEPercentData = {};
			const profitPercentData = {};
			let netWinLineName = this.netWinData;
			let netWinLineData = [];
			let netWinLineColor = '';
			let netWinLineYAxis = 0;

			const legendData = [this.$t('charts.netWin'), netWinLineName];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					netWinData.push(roundDecimal(res[i].house_net_win));
					promoCreditData.push(_.toInteger(res[i].bonus_amt));
					profitData.push(_.toInteger(res[i].house_net_win - res[i].bonus_amt));
					heData.push(roundDecimal((res[i].house_net_win / res[i].apv_bet) * 100, 2));
					HEPercentData[res[i].dim_ptt] = roundDecimal((res[i].house_net_win / res[i].apv_bet) * 100, 2);
					profitPercentData[res[i].dim_ptt] = roundDecimal(((res[i].house_net_win - res[i].bonus_amt) / res[i].apv_bet) * 100, 2);
				}
			}
			this.barOption3 = await mixBar_Line();
			// this.barOption3.title.text = this.$t('charts.overViewNetWinD');
			this.barOption3.tooltip.formatter = (params) => {
				let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				for (let i of params) {
					if(i.seriesName === this.$t('charts.HouseEdge')) {
						str += `${i.marker}${i.seriesName}: ${i.value}%<br/>`;
					} else {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
				}
				// <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#42a5f5"></span>${this.$t(
				// 		'charts.HouseEdge'
				// 	)}: ${HEPercentData[params[0].name]}%<br/>
				str += `
					<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ce93d8"></span>${this.$t(
						'charts.profitRate'
					)}: ${profitPercentData[params[0].name]}%`;
				return str;
			};
			this.barOption3.xAxis.axisLabel.formatter = (value) => {
				return this.$moment(value).format(dateType.BRIEF_DATE);
			};
			this.barOption3.grid = { top: '23%', left: '16%' };
			this.barOption3.tooltip.axisPointer.type = 'shadow';
			this.barOption3.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [
					{ name: legendData[0] },
					{ name: legendData[1], icon: 'circle' },
				],
			};
			this.barOption3.xAxis.data = xAxisData;
			this.barOption3.yAxis[0].axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.barOption3.series.markPoint = {
				label: {
					formatter: (parameter) => {
						let value;
						if (parameter.value >= 0) {
							value = nFormatter(parameter.value, 1);
						} else {
							value = `-${nFormatter(Math.abs(parameter.value), 1)}`;
						}
						return value;
					},
				},
				symbolSize: [1, 1],
				data: [
					{ type: 'max', name: '最大值', label: { position: 'top', color: 'white' } },
					{ type: '', name: '最小值', label: { position: 'top', color: 'white' } },
				],
			};
			if (netWinLineName === this.$t('charts.promoCredit')) {
				netWinLineData = promoCreditData;
				netWinLineColor = '#ffcdd2';
				netWinLineYAxis = 0;
			}
			if (netWinLineName === this.$t('charts.profit')) {
				netWinLineData = profitData;
				netWinLineColor = '#E1E100';
				netWinLineYAxis = 0;
			}
			if (netWinLineName === this.$t('charts.HouseEdge')) {
				netWinLineData = heData;
				netWinLineColor = '#42a5f5';
				netWinLineYAxis = 1;
			}
			this.barOption3.series = [
				{
					name: this.$t('charts.netWin'),
					type: 'bar',
					data: netWinData,
					color: COLORS.dark_green,
				},
				{
					name: netWinLineName,
					type: 'line',
					data: netWinLineData,
					color: netWinLineColor,
					yAxisIndex: netWinLineYAxis,
				},
			];
		},
		async dpstBarChart(res) {
			const xAxisData = [
				this.$t('charts.avgDeposit'),
				this.$t('charts.avgWithdraw'),
				this.$t('charts.avgRevenue')
			];
			if (res) {
				this.barOption4 = norBar({
					color: ['#388E3C', COLORS.orange_red, '#F9A825'],
					title: this.$t('charts.dpstSummary'),
					name: '',
					yAxis: [''],
					series: [],
				});
				const data = [res.avg_dpst_amt, res.avg_wdrl_amt, res.avg_revenue_amt];
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption4.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return `${xAxisData[params.componentIndex]}: ${roundOffFilter(data[params.componentIndex])}`;
							}
						},
						data: [data[i]]
					});
				}
				this.barOption4.grid.right = '15%';
				this.barOption4.xAxis.show = false;
				this.barOption4.yAxis[0].position = 'right';
				this.barOption4.tooltip.show = false;
				// this.barOption4.tooltip.formatter = (params) => {
				// 	let str = ``;
				// 	for (let i of params) {
				// 		str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
				// 	}
				// 	return str;
				// };
			}
		},
		async dpstChart(res) {
			const dpstData = [];
			const revenueData = [], wdrlData = [];
			const xAxisData = [];
			let selectName = this.dpstData;
			let selectedColor = [], selectedData = [];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					xAxisData.push(res[i].dim_ptt);
					dpstData.push(res[i].dpst_amt);
					wdrlData.push(res[i].wdrl_amt);
					revenueData.push(res[i].revenue_amt);
				}
			}
			if (selectName === this.$t('charts.wdrl')) {
				selectedData = wdrlData;
				selectedColor = ['#388E3C', COLORS.orange_red];
			} else if (selectName === this.$t('charts.revenue')) {
				selectedData = revenueData;
				selectedColor = ['#388E3C', '#F9A825'];
			}
			this.barOption5 = revenueChart({
				color: selectedColor,
				date: {
					name: this.$t('charts.date'),
					format: dateType.BRIEF_DATE,
				},
				xAxis: {
					data: xAxisData,
				},
				bar: {
					name: this.$t('charts.dpst'),
					data: dpstData,
				},
				line: {
					name: selectName,
					data: selectedData,
				},
			});
			this.barOption5.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [{ name: this.$t('charts.dpst') }, { name: selectName, icon: 'circle' }],
			};
			// this.barOption5.title.text = this.$t('charts.overViewDpst');
		},
		async dailyStickinessLineChart(res) {
			let xAxisData = [];
			const newSt = [], oldSt = [];
			const legendData = [this.$t('charts.newStickiness'), this.$t('charts.oldStickiness')];
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					if(i.dim_reg_age === 'oldbies') {
						oldSt.push(roundDecimal(i.stickiness * 100));
					}
					if(i.dim_reg_age === 'newbies') {
						newSt.push(roundDecimal(i.stickiness * 100));
					}
				}
				xAxisData = Array.from(new Set(xAxisData));
				this.lineOption5 = await line({
					title: this.$t('charts.gamingDailyStickiness'),
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: [
						{
							name: this.$t('charts.newStickiness'),
							type: 'line',
							color: COLORS.brown,
							data: newSt,
						},
						{
							name: this.$t('charts.oldStickiness'),
							type: 'line',
							color: COLORS.darker_Indigo,
							data: oldSt,
						}
					],
				});
				this.lineOption5.yAxis.axisLabel.formatter= (params) => {
					return `${roundOff(params)}%`;
				}
				this.lineOption5.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundDecimal(i.value)}%<br/>`;
					}
					return str;
				};
			}
		},
		async convertFunnelChart(res) {
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			this.funnelTmpData = {
				// title: this.$t('charts.newUsersConversion'),
				// subtext: this.$t('charts.avdDailyUsers'),
				legend: legendData,
				name: this.$t('charts.newUsersConversion'),
				data: {
					[this.$t('charts.regUsers')]: res.avg_reg_users,
					[this.$t('charts.loginUsers')]: res.avg_login_users,
					[this.$t('charts.gamingUsers')]: res.avg_gm_users,
					[this.$t('charts.dpstUsers')]: res.avg_dpst_01_users,
					[this.$t('charts.secondDpst')]: res.avg_dpst_02_users,
				},
				subTitle: [
					this.$t('charts.avgRegUsers'),
					this.$t('charts.avgLoginUsers'),
					this.$t('charts.avgGamingUsers'),
					this.$t('charts.avgDpstUsers'),
					this.$t('charts.avgSecondDpst')
				],
				subData: [
					res.ttl_reg_users,
					res.ttl_login_users,
					res.ttl_gm_users,
					res.ttl_dpst_01_users,
					res.ttl_dpst_02_users,
				]
			};
			this.funnelOption1 = await userFunnelChart(this.funnelTmpData);
		},
		async convertLineChart(res) {
			const regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [],
				xAxisData = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				// res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					regUsers.push(i.ttl_reg_users);
					loginUsers.push(i.ttl_login_users);
					gamingUsers.push(i.ttl_gm_users);
					dpstUsers.push(i.ttl_dpst_01_users);
					secDpstUsers.push(i.ttl_dpst_02_users);
				}
				this.lineOption3 = await line({
					title: '',
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: [
						{
							name: this.$t('charts.regUsers'),
							type: 'line',
							color: COLORS.dark_water_blue_green,
							data: regUsers,
						},
						{
							name: this.$t('charts.loginUsers'),
							type: 'line',
							color: COLORS.water_blue_green,
							data: loginUsers,
						},
						{
							name: this.$t('charts.gamingUsers'),
							type: 'line',
							color: COLORS.light_green,
							data: gamingUsers,
						},
						{
							name: this.$t('charts.dpstUsers'),
							type: 'line',
							color: COLORS.mustard,
							data: dpstUsers,
						},
						{
							name: this.$t('charts.secondDpst'),
							type: 'line',
							color: COLORS.wisteria[4],
							data: secDpstUsers,
						},
					],
				});
				this.lineOption3.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${toThousandslsFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async convertBarChart(res) {
			const data = [],
				dataMap = [],
				toolTipMap = [],
				xAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const regArr = [], loginArr = [], gamingArr = [], dpstArr = [], secDpstArr = [];
			const regPer = [], loginPer = [], gamingPer = [], dpstPer = [], secDpstPer = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					regUsers.push(i.ttl_reg_users);
					loginUsers.push(i.ttl_login_users);
					gamingUsers.push(i.ttl_gm_users);
					dpstUsers.push(i.ttl_dpst_01_users);
					secDpstUsers.push(i.ttl_dpst_02_users);
					regPer.push(roundDecimal(i.ttl_reg_users / i.ttl_reg_users * 100));
					loginPer.push(roundDecimal(i.ttl_login_users / i.ttl_reg_users * 100));
					gamingPer.push(roundDecimal(i.ttl_gm_users / i.ttl_reg_users * 100));
					dpstPer.push(roundDecimal(i.ttl_dpst_01_users / i.ttl_reg_users * 100));
					secDpstPer.push(roundDecimal(i.ttl_dpst_02_users / i.ttl_reg_users * 100));
					regArr.push((i.ttl_reg_users - i.ttl_login_users) / i.ttl_reg_users * 100);
					loginArr.push((i.ttl_login_users - i.ttl_gm_users) / i.ttl_reg_users * 100);
					gamingArr.push((i.ttl_gm_users - i.ttl_dpst_01_users) / i.ttl_reg_users * 100);
					dpstArr.push((i.ttl_dpst_01_users - i.ttl_dpst_02_users) / i.ttl_reg_users * 100);
					secDpstArr.push(i.ttl_dpst_02_users / i.ttl_reg_users * 100);
				}
				dataMap.push(secDpstUsers, dpstUsers, gamingUsers, loginUsers, regUsers);
				toolTipMap.push(secDpstPer, dpstPer, gamingPer, loginPer, regPer);
				data.push(
					{
						name: this.$t('charts.secondDpst'),
						type: 'bar',
						stack: 'all',
						color: '#A569BD',
						data: secDpstArr,
					},
					{
						name: this.$t('charts.dpstUsers'),
						type: 'bar',
						stack: 'all',
						color: '#FFDB5C',
						data: dpstArr,
					},
					{
						name: this.$t('charts.gamingUsers'),
						type: 'bar',
						stack: 'all',
						color: '#9FE6B8',
						data: gamingArr,
					},
					{
						name: this.$t('charts.loginUsers'),
						type: 'bar',
						stack: 'all',
						color: '#67E0E3',
						data: loginArr,
					},
					{
						name: this.$t('charts.regUsers'),
						type: 'bar',
						stack: 'all',
						color: '#32C5E9',
						data: regArr,
					},
				);
				this.barTmpData = {
					title: '',
					legend: legendData,
					name: this.$t('charts.newUsersDailyConversion'),
					xAxis: xAxisData,
					series: data,
				};
			}
			this.stackBarOption1 = await barPercent(this.barTmpData);
			this.stackBarOption1.tooltip.formatter = (params) => {
				let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				params.reverse();
				for(let i of params) {
					te += `${i.marker}${i.seriesName}: ${roundOffFilter(dataMap[i.componentIndex][i.dataIndex])} (${toolTipMap[i.componentIndex][i.dataIndex]}%)<br/>`;
				}
				return te;
			}
		},
		async retentionFunnelChart(res) {
			// const tmpGmUsers = {};
			const tmpPercent = [];
			const key = Object.keys(timeIntervalType['afwd']);
			const legendData = Object.values(timeIntervalType['afwd']);
			// legendData.unshift('all');
			const tmp = {
				color: [COLORS.orange_red, '#32C5E9', '#FFDB5C', '#A569BD'],
				// title: this.$t('charts.newUsersRetention'),
				// subtext: this.$t('member.avgDauNDAR'),
				legend: legendData,
				name: this.$t('charts.newUsersRetention'),
				data: {
					// 'all': roundDecimal(this.actAll.gm_cvt_users / this.actAll.data_term),
					// 'all': this.getExportResult.ctx_site_cvt_all.avg_gm_users
				},
			};
			if (res) {
				res.sort((a, b) => (parseInt(a.tag_reg_term) > parseInt(b.tag_reg_term) ? 1 : -1));
				for (let j of key) {
					for (let i = 0; i < res.length; i += 1) {
						if (j === res[i].tag_reg_term) {
							// tag_reg_term
							// tmp.data[j] = roundDecimal(res[i].gm_users / res[i].data_term); // gm_users / data_term
							// tmp.data[j] = res[i].gm_cvt_users;
							tmp.data[j] = res[i].avg_gm_users_real;
							// tmpGmUsers[j] = res[i].retention;
							tmpPercent.push(res[i].retention);
						}
					}
				}
				const userValue = Object.values(tmp.data);
				this.funnelOption2 = userFunnelChart(tmp);
				this.funnelOption2.tooltip.formatter = (params) => {
					return `${params.marker}${params.name}: ${roundOffFilter(userValue[params.dataIndex])} (${rdPercent(
						tmpPercent[params.dataIndex]
					)})`;
				};
				this.funnelOption2.series[0].label.formatter = (params) => {
					return `${params.name}\n${roundOffFilter(userValue[params.dataIndex])}\n${rdPercent(
						tmpPercent[params.dataIndex]
					)}`;
				};
				// return tmpGmUsers;
			}
		},
		async retentionBarChart(res) {
			const data = [];
			let xAxisData = [];
			const per = {}, percent = {}, toolTipData = {}, tmp = {
				'cvt_gm': [],
				'0~1': [],
				'2~7': [],
				'8~29': [],
			};
			const legendData = Object.values(timeIntervalType['afwd']);
			legendData.unshift(this.$t('charts.gameConver'));
			if (res) {
				// res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					tmp[i.tag_reg_term].push(i.avg_gm_users_real);
					percent[i.tag_reg_term] = [];
					per[i.dim_ptt] = [];
					toolTipData[i.dim_ptt] = [];
				}
				xAxisData = Array.from(new Set(xAxisData));
				res.sort((a, b) => (a.retention < b.retention ? 1 : -1));
				for(let i = 0; i < xAxisData.length; i += 1) {
					for(let j of res) {
						if(xAxisData[i] === j.dim_ptt) {
							per[xAxisData[i]].push(j);
							toolTipData[xAxisData[i]].push(j.retention);
						}
					}
				}
				for (let i in per) {
					for(let j = 0; j < per[i].length - 1; j += 1) {
						percent[per[i][j].tag_reg_term].push((per[i][j].retention - per[i][j+1].retention) * 100);
						if(j === per[i].length - 2) {
							percent[per[i][j+1].tag_reg_term].push(per[i][j+1].retention * 100);
						}
					}
				}
				data.push(
					{
						name: timeIntervalType['afwd']['8~29'],
						type: 'bar',
						stack: 'total',
						color: '#A569BD',
						data: percent['8~29'],
					},
					{
						name: timeIntervalType['afwd']['2~7'],
						type: 'bar',
						stack: 'total',
						color: '#FFDB5C',
						data: percent['2~7'],
					},
					{
						name: timeIntervalType['afwd']['0~1'],
						type: 'bar',
						stack: 'total',
						color: '#32C5E9',
						data: percent['0~1'],
					},
					{
						name: timeIntervalType['afwd']['cvt_gm'],
						type: 'bar',
						stack: 'total',
						color: COLORS.orange_red,
						data: percent['cvt_gm'],
					},
				);
				this.barTmpData = {
					// title: this.$t('charts.newUsersDailyRetention'),
					legend: legendData,
					name: this.$t('charts.newUsersDailyRetention'),
					xAxis: xAxisData,
					series: data,
				};
				this.stackBarOption2 = await barPercent(this.barTmpData);
				this.stackBarOption2.yAxis[0].min = 0;
				this.stackBarOption2.yAxis[0].max = 100;
				this.stackBarOption2.legend.selectedMode = false;
				this.stackBarOption2.tooltip.formatter = (params) => {
					let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					params.reverse();
					for(let i = 0; i < params.length; i += 1) {
						let re = toolTipData[params[i].axisValue][i];
						te += `${params[i].marker}${params[i].seriesName}: ${roundDecimal(re * 100)}%<br/>`;
					}
					return te;
				}
			}
		},
		async retentionLineChart(res) {
			const tmpData = [], xAxisData = [];
			const tmp = {
				'cvt_gm': [],
				'0~1': [],
				'2~7': [],
				'8~29': [],
			};
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					for (let j of this.groupKeyAfwd) {
						if (_.toString(i.tag_reg_term) === j) {
							tmp[i.tag_reg_term].push(i);
						}
					}
				}
				for (let i in tmp) {
					let usersArr = [];
					let obj1 = {
						name: this.groupNameAfwd[i],
						type: 'line',
						data: [],
					};
					for (let j of tmp[i]) {
						usersArr.push(j.avg_gm_users_real);
					}
					obj1.data = usersArr;
					tmpData.push(obj1);
				}
				this.lineOption4 = await line({
					color: [COLORS.orange_red, '#32C5E9', '#FFDB5C', '#A569BD'],
					// title: this.$t('charts.newUsersDailyRetention'),
					legend: this.groupValueAfwd,
					name: '',
					xAxis: Array.from(new Set(xAxisData)),
					series: tmpData,
				});
				this.lineOption4.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async regPieChart(res) {
			const gmUsersData = [];
			let newGmUsers = 0, oldGmUsers = 0;
			const legendData = this.groupValueBkwd;
			if (res) {
				for (let j of this.groupKeyBkwd) {
					for (let i = 0; i < res.length; i += 1) {
						if (j === res[i].tag_reg_term) {
							gmUsersData.push({
								value: res[i].gm_users || 0,
								name: this.groupNameBkwd[j],
							});
						} else {
							gmUsersData.push({
								value: 0,
								name: this.groupNameBkwd[j],
							});
						}
					}
				}
				for (let i of this.newGroup) {
					for (let j of res) {
						if (i === j.tag_reg_term) {
							newGmUsers += j.gm_users;
						}
					}
				}
				for (let i of this.oldGroup) {
					for (let j of res) {
						if (i === j.tag_reg_term) {
							oldGmUsers += j.gm_users;
						}
					}
				}
				this.pieOption2 = pie({
					color: this.groupColor,
					title: '',
					legend: legendData,
					name: '',
					series: gmUsersData,
				});
				this.pieOption2.tooltip.formatter = (params) => {
					return `${params.marker}${params.name}: ${roundOffFilter(params.value)} (${params.percent}%)`;
				};
				this.pieOption2.series.push({
					name: '',
					type: 'pie',
					radius: [0, '30%'],
					label: {
						position: 'inner',
					},
					labelLine: {
						show: false,
					},
					data: [
						{ name: this.$t('customer.newMember'), value: newGmUsers },
						{ name: this.$t('customer.oldMember'), value: oldGmUsers },
					],
				});
			}
		},
		async regLineChart(res) {
			let xAxisData = [];
			const tmp = {
				1: [],
				3: [],
				7: [],
				15: [],
				30: [],
				90: [],
				360: [],
				'-1': [],
			};
			const gmUsersData = [];
			const legendData = this.groupValueBkwd;
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
					xAxisData = Array.from(new Set(xAxisData));
					for (let j of this.groupKeyBkwd) {
						if (i.tag_reg_term === j) {
							tmp[i.tag_reg_term].push(i);
						}
					}
				}
				for (let i in tmp) {
					let gmArr = [];
					for (let j of tmp[i]) {
						gmArr.push(j.gm_users);
					}
					this.allDataPercent = await this.getPercent(tmp[1], tmp[3], tmp[7], tmp[15], tmp[30], tmp[90], tmp[360] , tmp['-1']);
					gmUsersData.push({
						name: this.groupNameBkwd[i],
						type: 'line',
						data: gmArr
					});
				}
				this.lineOption5 = line({
					color: this.groupColor,
					title: this.$t('charts.onUsers'),
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: gmUsersData,
				});
				this.lineOption5.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${toThousandslsFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async regBarChart(res) {
			const xAxisData = [], tmpGmUsers = [];
			const legendData = Object.values(timeIntervalType['bkwd']);
			if (res) {
				for (let i of res) {
					xAxisData.push(i.dim_ptt);
				}
				for (let i in legendData) {
					tmpGmUsers.push({
						name: legendData[i],
						type: 'bar',
						stack: 'all',
						data: this.allDataPercent.tmpGmUsersArr[i],
					});
				}
			}
			this.stackBarOption3 = await barPercent({
				color: this.groupColor,
				title: this.$t('charts.onUsers'),
				legend: legendData,
				name: '',
				xAxis: Array.from(new Set(xAxisData)),
				series: tmpGmUsers,
			});
			this.stackBarOption3.tooltip.formatter = (params) => {
				let te = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				for(let i of params) {
					te += `${i.marker}${i.seriesName}: ${roundDecimal(i.value)}%<br/>`;
				}
				return te;
			}
		},
		async gameKindChart(gaming) {
			gaming.sort((a, b) => (a.game_kind_group > b.game_kind_group ? 1 : -1));
			const res = await trans(gaming, 'game_kind_group', 'apv_bet');
			gaming.sort((a, b) => (a.game_kind_group > b.game_kind_group ? 1 : -1));
			const res2 = await trans(gaming, 'game_kind_group', 'house_net_win');
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			if (res.length && res2.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					data2.push(res2[i].house_net_win);
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(res[i].game_kind_group + `_${roundOffFilter(res[i].apv_bet)}` + `_(${rdPercent(res[i].apv_bet / totalBet)})`);
					yAxisData2.push(
						res2[i].game_kind_group + `_${roundOffFilter(res2[i].house_net_win)}` + `_(${rdPercent(res2[i].house_net_win / totalNetWin)})`
					);
				}
				this.gkName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.gkName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.gkValue.set(this.$t('charts.turnoverDesc'), data1);
				this.gkValue.set(this.$t('charts.netWinDesc'), data2);
				this.barOption6 = summaryBar({
					color: COLORS.silver[7],
					title: this.$t('member.gameKindSummary'),
					subtext: this.$t('common.interactable'),
					yAxis: this.gkName.get(this.switchValue),
					series: this.gkValue.get(this.switchValue),
				});
				this.barOption6.xAxis.show = false;
			}
		},
		async brandChart(gaming) {
			gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
			const res = await trans(gaming, 'dim_brand_code', 'apv_bet');
			gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
			const res2 = await trans(gaming, 'dim_brand_code', 'house_net_win');
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			if (res.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					data2.push(res2[i].house_net_win);
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(res[i].dim_brand_code + `_${roundOffFilter(res[i].apv_bet)}` + `_(${rdPercent(res[i].apv_bet / totalBet)})`);
					yAxisData2.push(res2[i].dim_brand_code + `_${roundOffFilter(res2[i].house_net_win)}` + `_(${rdPercent(res2[i].house_net_win / totalNetWin)})`);
				}
				this.bdName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.bdName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.bdValue.set(this.$t('charts.turnoverDesc'), data1);
				this.bdValue.set(this.$t('charts.netWinDesc'), data2);
				this.barOption7 = summaryBar({
					color: COLORS.silver[6],
					title: this.$t('member.barndSummary'),
					subtext: this.$t('common.interactable'),
					yAxis: this.bdName.get(this.switchValue),
					series: this.bdValue.get(this.switchValue),
				});
				this.barOption7.xAxis.show = false;
			}
		},
		async gameThemeChart(gaming) {
			gaming.sort((a, b) => (a.game_brand_theme > b.game_brand_theme ? 1 : -1));
			const res = await trans(gaming, 'game_brand_theme', 'apv_bet');
			gaming.sort((a, b) => (a.game_brand_theme > b.game_brand_theme ? 1 : -1));
			const res2 = await trans(gaming, 'game_brand_theme', 'house_net_win');
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			if (res.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					data2.push(res2[i].house_net_win);
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(
						res[i].game_brand_theme +
						`_${roundOffFilter(res[i].apv_bet)}` +
						`_(${rdPercent(res[i].apv_bet / totalBet)})`
					);
					yAxisData2.push(
						res2[i].game_brand_theme +
						`_${roundOffFilter(res2[i].house_net_win)}` +
						`_(${rdPercent(res2[i].house_net_win / totalNetWin)})`
					);
				}
				this.gtName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.gtName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.gtValue.set(this.$t('charts.turnoverDesc'), data1);
				this.gtValue.set(this.$t('charts.netWinDesc'), data2);
				this.barOption8 = summaryBar({
					color: COLORS.silver[8],
					title: this.$t('member.gameThemeSummary'),
					yAxis: this.gtName.get(this.switchValue),
					series: this.gtValue.get(this.switchValue),
				});
				this.barOption8.xAxis.show = false;
			}
		},
		async dpstWayChart(res) {
			const legendData = [],
				seriesData = [];
			if (res.length) {
				let countSum = [];
				for (let i of res) {
					legendData.push(i.dim_txn_type);
					countSum.push(i.dpst_count);
					seriesData.push({
						value: i.dpst_count,
						name: i.dim_txn_type,
					});
				}
				let graphicText = countSum.reduce((a, b) => a + b);
				graphicText = this.$t('member.totalDpstCount') + '\n' + toThousandslsFilter(graphicText);
				this.pieOption3 = norPie();
				this.pieOption3.color = JSON.parse(JSON.stringify(COLORS.green_sea)).reverse();
				this.pieOption3.title.text = this.$t('member.eachTypeDpstCount');
				this.pieOption3.legend.data = legendData;
				this.pieOption3.tooltip.formatter = (params) => `${params.marker}${params.name}: ${roundOffFilter(params.value)}`;
				this.pieOption3.graphic.style.text = graphicText;
				this.pieOption3.series[0].data = seriesData;
			}
		},
		async popularCharts(res) {
			let gmData = [],
				hour = [],
				tmp1 = [];
			const week = [
				this.$t('common.saturday'),
				this.$t('common.friday'),
				this.$t('common.thursday'),
				this.$t('common.wednesday'),
				this.$t('common.tuesday'),
				this.$t('common.monday'),
				this.$t('common.sunday'),
			];
			if (res.length) {
				for (let i of res) {
					const dayIndex = Math.abs(i.dim_day_of_week - 7);
					gmData.push([i.dim_hour, dayIndex, i.avg_gm_users]);
					tmp1.push(i.avg_gm_users);
				}
				for (let i = 0; i < 24; i += 1) {
					hour.push(i);
				}
				const max1 = tmp1.reduce((a, b) => Math.max(a, b));
				gmData = gmData.map(function (item) {
					return [item[0], item[1], item[2] || '-'];
				});

				this.heatMapOption1 = heatMap();
				this.heatMapOption1.visualMap.color = JSON.parse(JSON.stringify(COLORS.peter_river)).reverse();
				this.heatMapOption1.title.text = this.$t('member.eachTimeGamePeople');
				this.heatMapOption1.tooltip.formatter = (params) =>
					`${this.$t('charts.day')}: ${week[params.data[1]]}<br/>${this.$t('charts.hour')}: ${hour[params.data[0]]}<br/>${
						params.marker
					}${this.$t('charts.avgGmUsers')}: ${roundOffFilter(params.data[2])}`;
				this.heatMapOption1.yAxis.data = week;
				this.heatMapOption1.xAxis.data = hour;
				this.heatMapOption1.visualMap.max = max1;
				this.heatMapOption1.series[0].data = gmData;
			}
		},
		async getPercent(ze1, ze3, ze7, ze15, ze30, ze90, ze360, zeAll) {
			// let ze1Arr = [], ze3Arr = [], ze7Arr = [], ze15Arr = [], ze30Arr = [], ze90Arr = [], ze360Arr = [], zeAllArr = [];
			let gm1Arr = [], gm3Arr = [], gm7Arr = [], gm15Arr = [], gm30Arr = [], gm90Arr = [], gm360Arr = [], gmAllArr = [];
			// let nw1Arr = [], nw3Arr = [], nw7Arr = [], nw15Arr = [], nw30Arr = [], nw90Arr = [], nw360Arr = [], nwAllArr = [];
			// let bp1Arr = [], bp3Arr = [], bp7Arr = [], bp15Arr = [], bp30Arr = [], bp90Arr = [], bp360Arr = [], bpAllArr = [];
			for(let i of this.dailyGamingUsers) {
				for(let j of ze1) {
					if(i.dim_ptt === j.dim_ptt) {
						gm1Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze3) {
					if(i.dim_ptt === j.dim_ptt) {
						gm3Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze7) {
					if(i.dim_ptt === j.dim_ptt) {
						gm7Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze15) {
					if(i.dim_ptt === j.dim_ptt) {
						gm15Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze30) {
					if(i.dim_ptt === j.dim_ptt) {
						gm30Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze90) {
					if(i.dim_ptt === j.dim_ptt) {
						gm90Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of ze360) {
					if(i.dim_ptt === j.dim_ptt) {
						gm360Arr.push((j.gm_users / i.gm_users * 100));
					}
				}
				for(let j of zeAll) {
					if(i.dim_ptt === j.dim_ptt) {
						gmAllArr.push((j.gm_users / i.gm_users * 100));
					}
				}
			}
			return {
				// tmpBetArr: [ze1Arr, ze3Arr, ze7Arr, ze15Arr, ze30Arr, ze90Arr, ze360Arr, zeAllArr],
				tmpGmUsersArr: [gm1Arr , gm3Arr, gm7Arr, gm15Arr, gm30Arr, gm90Arr, gm360Arr, gmAllArr],
				// tmpNetWinARR: [nw1Arr, nw3Arr, nw7Arr, nw15Arr, nw30Arr, nw90Arr, nw360Arr, nwAllArr],
				// tmpBetPuArr: [bp1Arr, bp3Arr, bp7Arr, bp15Arr, bp30Arr, bp90Arr, bp360Arr, bpAllArr]
			}
		},
		allClean() {
			this.cardData = {};
			this.barOption1 = {};
			this.barOption2 = {};
			this.barOption3 = {};
			this.barOption4 = {};
			this.barOption5 = {};
			this.stackBarOption1 = {};
			this.stackBarOption2 = {};
			this.stackBarOption3 = {};
			this.lineOption1 = {};
			this.lineOption2 = {};
			this.lineOption3 = {};
			this.lineOption4 = {};
			this.lineOption5 = {};
			this.pieOption1 = {};
			this.pieOption2 = {};
			this.pieOption3 = {};
			this.pieOption4 = {};
			this.funnelOption1 = {};
			this.funnelOption2 = {};
			this.barOption6 = {};
			this.barOption7 = {};
			this.barOption8 = {};
			this.heatMapOption1 = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		resetGamingCharts() {
			this.brandChart(this.gaming);
			this.gameKindChart(this.gaming);
			this.gameThemeChart(this.gaming);
		},
		getBrand(params) {
			const brandFilter = [];
			const array = params.name.split('_');
			const name = array[0];
			for(let i of this.gaming) {
				if(name === i.dim_brand_code) {
					brandFilter.push(i);
				}
			}
			this.gameKindChart(brandFilter);
			this.gameThemeChart(brandFilter);
		},
		getGameKind(params) {
			const gameKindFilter = [];
			const array = params.name.split('_');
			const name = array[0];
			for(let i of this.gaming) {
				if(name === i.game_kind_group) {
					gameKindFilter.push(i);
				}
			}
			this.brandChart(gameKindFilter);
			this.gameThemeChart(gameKindFilter);
		},
		getGameTheme(params) {
			const gameThemeFilter = [];
			const arr1 = params.name.split('-');
			const arr2 = arr1[1].split('_');
			const name = arr2[0];
			for(let i of this.gaming) {
				if(name === i.game_theme_name) {
					gameThemeFilter.push(i);
				}
			}
			this.brandChart(gameThemeFilter);
			this.gameKindChart(gameThemeFilter);
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		async updateNetWinData(val) {
			this.netWinData = val;
			if (this.termly.length !== 0) {
				this.netWinChart(this.termly);
			} else {
				this.allClean();
				let noMatchData = this.$t('error.noMatchData');
				this.errorDialogStatus(noMatchData);
			}
		},
		async updateTurnoverData(val) {
			this.turnOverData = val;
			if (this.termly.length !== 0) {
				this.turnOverChart(this.termly);
			} else {
				this.allClean();
				let noMatchData = this.$t('error.noMatchData');
				this.errorDialogStatus(noMatchData);
			}
		},
		async updateDpstData(val) {
			this.dpstData = val;
			if (this.termly.length !== 0) {
				this.dpstChart(this.termly);
			} else {
				this.allClean();
				let noMatchData = this.$t('error.noMatchData');
				this.errorDialogStatus(noMatchData);
			}
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		async updateData(val) {
			this.switchValue = val;
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { 'top': NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
		switchValue: function (value) {
			this.barOption6 = summaryBar({
				color: COLORS.silver[7],
				title: this.$t('member.gameKindSummary'),
				subtext: this.$t('common.interactable'),
				yAxis: this.gkName.get(value),
				series: this.gkValue.get(value),
			});
			this.barOption7 = summaryBar({
				color: COLORS.silver[6],
				title: this.$t('member.barndSummary'),
				subtext: this.$t('common.interactable'),
				yAxis: this.bdName.get(value),
				series: this.bdValue.get(value),
			});
			this.barOption8 = summaryBar({
				color: COLORS.silver[8],
				title: this.$t('member.gameThemeSummary'),
				yAxis: this.gtName.get(value),
				series: this.gtValue.get(value),
			});
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters([
			'getDomain',
			'getDialogShow',
			'getStatusDialog',
			'getOperatorDefaultRouter',
			'getOpenAutoSearch',
		]),
	},
	created() {},
};
</script>